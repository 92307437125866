import $axios from "../../plugins/axios";

// store/modules/spotify.js
const state = {
    token: null,
    player: null,
    deviceId: null,
    spotifyRefreshToken: null
};

const mutations = {
    setSpotifyToken(state, token) {
        state.token = token;
    },
    setSpotifyPlayer(state, player) {
        state.player = player;
    },
    setDeviceId(state, deviceId) {
        state.deviceId = deviceId;
    },
    setSpotifyRefreshToken(state, spotifyRefreshToken) {
        state.spotifyRefreshToken = spotifyRefreshToken;
    },
};

const actions = {
    updateSpotifyToken({ commit }, token) {
        commit('setSpotifyToken', token);
    },
    updateSpotifyPlayer({ commit }, player) {
        commit('setSpotifyPlayer', player); 
    },
    updateDeviceId({ commit }, deviceId) {
        commit('setDeviceId', deviceId);
    },
    updateSpotifyRefreshToken({ commit }, spotifyRefreshToken) {
        commit('setSpotifyRefreshToken', spotifyRefreshToken);
    },
    async refreshAccessToken({ commit ,state}) {
        const refreshToken = state.spotifyRefreshToken;
        try {
            const response = await $axios.post('/spotify/refresh', { refreshToken });
            const spotifyAccessToken = response.data.access_token;
            if (spotifyAccessToken) {
                commit('setSpotifyToken', spotifyAccessToken); // Update token in Vuex
                localStorage.setItem('spotifyToken', spotifyAccessToken);
            } else {
                throw new Error('Failed to refresh access token');
            }
        } catch (error) {
            console.error("Error refreshing access token:", error);
            throw error;
        }
    },
    async initSpotifyPlayer({ commit }, token) {
        return new Promise((resolve, reject) => {
            const player = new window.Spotify.Player({
                name: 'ReflexRadio Player',
                getOAuthToken: cb => { cb(token); },
                volume: 0.5,
            });

            player.addListener('ready', ({ device_id }) => {
                commit('setSpotifyPlayer', player);
                commit('setDeviceId', device_id);
                resolve(player);
            });
            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
                reject(new Error('Device not ready.'));
            });
            player.addListener('initialization_error', ({ message }) => {
                console.error('Initialization error:', message);
                reject(new Error(`Initialization error: ${message}`)); // Reject with the error message
            });
    
            player.addListener('authentication_error', ({ message }) => {
                console.error('Authentication error:', message);
                reject(new Error(`Authentication error: ${message}`)); // Reject with the error message
            });

            player.addListener('playback_error', ({ message }) => {
                console.error('Playback error:', message);
                reject(new Error(`Playback error: ${message}`)); // Reject with the error message
            });

            player.connect().then(success => {
                if (!success) {
                    reject(new Error('Failed to connect to the player.')); // Reject if connection fails
                }
            }).catch(err => {
                console.error('Error during player connection:', err);
                reject(new Error('Error connecting to the player.')); // Handle connection error
            });
            
        });
    },
    async playSong({ state }, trackUri) {
        const player = state.player;
        const deviceId = state.deviceId;
        const token = state.token;
    
        if (player && deviceId) {
            try {
                const response = await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
                    method: 'PUT',
                    body: JSON.stringify({ uris: [trackUri] }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
    
                if (response.ok) {
                    await player.resume();
                    console.log('Playback started');
                } else {
                    const errorData = await response.json();
                    console.error('Failed to start playback:', errorData);
    
                    throw new Error(`Playback failed: ${errorData.error.message}`);
                }
            } catch (err) {
                console.error('Error playing song:', err);
    
                this.dispatch('showSnackbarMessage', {
                    message: err.message,
                    duration: 4000,
                    mode: 'fail'
                });
                throw err;
            }
        } else {
            console.error("Player or device ID is not initialized.");
            throw new Error("Player or device ID is not initialized.");
        }
    }
,    
    
    async togglePlay({ state }) {
        const player = state.player;
        if (!player) {
            console.error("Player is not initialized.");
            return;
        }

        try {
            await player.togglePlay();
            console.log('Toggled playback!');
        } catch (error) {
            console.error('Error toggling playback:', error);
        }
    },
    async pause({ state }) {
        const player = state.player;
        if (player) {
            try {
                await player.pause();
                console.log('Paused playback');
            } catch (err) {
                console.error('Error pausing:', err);
            }
        } else {
            console.error("Player is not initialized.");
        }
    },
};

const getters = {
    spotifyToken: (state) => state.token,
    spotifyRefreshToken: (state) => state.token,
    spotifyPlayer: (state) => state.player,
    deviceId: (state) => state.deviceId,
}

export default {
    state,
    mutations,
    actions,
    getters,
};
