import StoreService from '../../services/store.service';
import UserService from "../../services/user.service";

export default async function subscriptionCheck({to, next, store, nextMiddleware}) {

	const user = await UserService.getOne(store.getters['auth/getCurrentUser'].id)
	const pathSubscriptionMissing = '/subscription-missing';
	const pathAddCard = '/cards';

	if ((user.role_id === 2 || user.role_id === 4) && (user.account.has_card === false && (user.account.is_apple_user === false))) {

		return next({
			path: pathAddCard,
		});
	}

	if (user.role_id === 3 || user.role_id === 5) {

		StoreService.getSingleStore(user.store_id).then((res) => {

			if (!res.subscription_id) {

				return next({
					path: pathSubscriptionMissing,
				});
			}

			return nextMiddleware();
		}).catch(() => {

			store.dispatch('showSnackbarMessage', {
				message: 'Failed. Please, try again.',
				duration: 8000,
				mode: 'fail',
			});
		});
	}

	return nextMiddleware();
}
