const keys = {
    appleMusic: {
        developerToken:'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiIsImtpZCI6IlJaODlCUkg4M04ifQ.eyJpc3MiOiI0Wjk2OUE0N0ZOIiwiZXhwIjoxNzQ1NjY5NjQ0LCJpYXQiOjE3MzAxMTc2NDR9.sW3wqbGMY5JZm7pRkdW3grDpJfPIrkA4f2Lfs2llcUH9nBhywjPGbLzUXStC7iIyJZn2QelHU9bBPkFSlLHW-A',
         name: 'Reflex Radio Web app',
        build: 'ver1',
        version: 'ver1',
    },
};

export default keys;
