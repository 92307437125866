<template>
  <md-dialog :md-active.sync="showModal" :md-click-outside-to-close="false">

    <md-dialog-title></md-dialog-title>
    <AppInputComponent style="padding-bottom: 0; margin-bottom: 5px" class="create-form__field" required
      placeholder="Search in apple music ..." v-model="searchTerm" />

    <div class="scrollbar-div scrollbar-apple"
      v-if="searchTerm && !loading && searchResults && searchResults.data.results.songs && searchResults.data.results.songs.data">
      <div class="song" v-for="(song, index) in searchResults.data.results.songs.data" :key="index"
        @click="appleSong(song)">
        <div class="song-image">
          <img width="40px" height="40px" :src="getUrl(song.attributes.artwork, 40)"
            alt="`artwork of track ${track.attributes.name}`" />
        </div>

        <div class="song-details">
          <p :class="song.attributes.contentRating === 'explicit' && 'explicit-content'">{{ song.attributes.name }} ,
            {{ song.attributes.artistName }}</p>
          <a>{{ song.attributes.albumName }}</a>
        </div>
      </div>
    </div>
    <!-- Spotify Music Search -->
    <AppInputComponent style="padding-bottom: 0; margin-bottom: 10;" class="create-form__field" required
      placeholder="Search in Spotify ..." v-model="searchTermSpotify"
      v-if="song.status_flag == 'NO_MATCHES' || !isEditFormModal" />

    <div class="scrollbar-div scrollbar-spotify"
      v-if="(song.status_flag == 'NO_MATCHES' || !isEditFormModal) && searchTermSpotify && !loadingSpotify && searchResultsSpotify && searchResultsSpotify.tracks && searchResultsSpotify.tracks.items">
      <div class="song" v-for="(song, index) in searchResultsSpotify.tracks.items" :key="index"
        @click="spotifySong(song)">
        <div class="song-image">
          <img width="40px" height="40px" :src="song.album.images[2].url" alt="`artwork of track ${song.name}`" />
        </div>

        <div class="song-details">
          <p :class="song.explicit && 'explicit-content'">{{ song.name }} , {{ song.artists[0].name }}</p>
          <a>{{ song.album.name }}</a>
        </div>
      </div>
    </div>

    <form class="md-layout create-form" style="top: 22px;position: relative;z-index: 1;">
      <div
        class="md-layout-item md-raised md-xlarge-size-65 md-large-size-65 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <AppInputComponent class="create-form__field" required placeholder="Song Title" v-model="$v.song.title.$model"
          :error="$v.song.title.$invalid && $v.song.title.$dirty" errorText="Song Title is required" />
      </div>

      <div
        class="md-layout-item md-raised md-xlarge-size-95 md-large-size-95 md-medium-size-100 md-small-size-100 md-xsmall-size-100 upload-cont">

        <p v-if="!isEditFormModal" class="file-upload-label">Upload a song</p>
        <div class="upload-dialog__preview">
          <p v-if="previewUrl" class="preview-image">
            {{ previewUrl }}
          </p>
          <p v-if="song.original_name" class="preview-image">
            {{ song.original_name }}
          </p>
        </div>

        <input v-if="!isEditFormModal || song.processing_status_id == 5" type="file" @change="showPreview($event)"
          ref="hiddenInput" class="input-file">
        <md-button v-if="!isEditFormModal || song.processing_status_id == 5"
          class="md-raised md-primary choose-file-btn md-button md-primary md-raised md-theme-light md-theme-default"
          @click.native="$refs.hiddenInput.click()">Choose Song
        </md-button>
      </div>
      <div class="photo-error" v-if="(!isEditFormModal  || song.processing_status_id == 5) && showPhotoError">{{
        photoError }}
      </div>

      <md-chips class="md-primary shake-on-error" v-model="genres" md-placeholder="Add genre...">
        <div class="md-helper-text">(Press "Enter" to add a genre)</div>
      </md-chips>
      <div
        class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <md-checkbox v-model="isRRPicks" />
        <span style="vertical-align: super">Reflex Radio Picks</span>
      </div>
      <div class="Licensed-border">
        <div
          class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
          <md-checkbox v-model="isLicensed" />
          <span style="vertical-align: super">Licensed music</span>
        </div>

        <div
          class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
          <AppInputComponent :disabled="!isLicensed" :controlType="isLicensed ? 'date' : 'input'"
            :disabledDates="disabledDates" :dateLabel="'License End Date'" placeholder="License End Date"
            class="recurring-form__field date-pick-field" required v-model="song.label_end_date"
            errorText="License End Date is required" />
        </div>

        <div
          class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
          <div class="md-layout md-gutter">
            <div
              class="md-layout-item md-xlarge-size-80 md-large-size-60 md-medium-size-80 md-small-size-80 md-xsmall-size-80">
              <AppInputComponent :disabled="!isLicensed" v-show="!showCreatePublisher"
                class="song-form__field select-field" controlType="select" required placeholder="Choose Publisher"
                v-model="$v.song.publisher_id.$model"
                :error="$v.song.publisher_id.$invalid && $v.song.publisher_id.$dirty"
                errorText="Publisher selection is required">

                <option v-for="(publisher, index) in publishers" :key="index" :value="publisher.id">
                  {{ publisher.name }}
                </option>

              </AppInputComponent>
              <AppInputComponent :disabled="!isLicensed" v-show="showCreatePublisher" class="room-form__field" required
                placeholder="Enter Publisher name" v-model="$v.newPublisherName.$model"
                :error="$v.newPublisherName.$invalid && $v.newPublisherName.$dirty"
                errorText="Publisher name is required" />
            </div>
            <div class="md-layout-item">
              <md-button :disabled="!isLicensed" v-show="showCreatePublisher === false" id="create-publisher-button"
                class="md-primary dialog__btn md-raised md-theme-light"
                @click.native="showCreatePublisher = true">Create
              </md-button>
              <md-button :disabled="!isLicensed" v-show="showCreatePublisher === true" id="save-publisher-button"
                class="md-primary dialog__btn md-raised md-theme-light" @click.native="createPublisher()">Save
              </md-button>
            </div>
          </div>
        </div>

        <div class="active-div">
          <div class="status-label" style="font-size: 18px;padding-bottom: 5px">STATUS:
            <span v-if="song.active" style="color: rgba(38, 125, 36, 1)">ACTIVE</span>
            <span v-else style="color: red">INACTIVE</span>
          </div>

          <toggle-button v-model="song.active" color="#a156a0" :disabled="!isLicensed" :sync="true" :width=64
            :font-size=17 :height=30 />
        </div>
      </div>
      <div
        class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <md-checkbox v-model="isPopular" />
        <span style="vertical-align: super">Popular song</span>
      </div>
      <div
        class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <div class="md-layout md-gutter">
          <div
            class="md-layout-item md-xlarge-size-80 md-large-size-60 md-medium-size-80 md-small-size-80 md-xsmall-size-80">
            <AppInputComponent v-show="!showCreateArtist" class="song-form__field select-field" controlType="select"
              required placeholder="Choose Artist" v-model="$v.song.artist_id.$model"
              :error="$v.song.artist_id.$invalid && $v.song.artist_id.$dirty" errorText="Artist selection is required">

              <option v-for="(artist, index) in artists" :key="index" :value="artist.id">{{ artist.name }}
              </option>

            </AppInputComponent>
            <AppInputComponent v-show="showCreateArtist" class="room-form__field" required
              placeholder="Enter Artist name" v-model="$v.newArtistName.$model"
              :error="$v.newArtistName.$invalid && $v.newArtistName.$dirty" errorText="Artist name is required" />
          </div>
          <div class="md-layout-item">
            <md-button v-show="showCreateArtist === false" class="md-primary dialog__btn md-raised md-theme-light"
              @click="showCreateArtist = true">Create
            </md-button>
            <md-button v-show="showCreateArtist === true" class="md-primary dialog__btn md-raised md-theme-light"
              @click="createArtist()">Save
            </md-button>
          </div>
        </div>
      </div>


      <div
        class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <div class="md-layout md-gutter">
          <div
            class="md-layout-item md-xlarge-size-80 md-large-size-60 md-medium-size-80 md-small-size-80 md-xsmall-size-80">
            <AppInputComponent v-show="!showCreateAlbum" class="song-form__field select-field" controlType="select"
              required placeholder="Choose Album" v-model="song.album_id" errorText="Album selection is required">

              <option v-for="(album, index) in albums" :key="index" :value="album.id">
                {{ album.name }}
              </option>

            </AppInputComponent>
            <AppInputComponent v-show="showCreateAlbum" class="room-form__field" required placeholder="Enter Album name"
              v-model="newAlbumName" errorText="Publisher name is required" />
          </div>
          <div class="md-layout-item">
            <md-button v-show="showCreateAlbum === false" class="md-primary dialog__btn md-raised md-theme-light"
              @click.native="showCreateAlbum = true">Create
            </md-button>
            <md-button v-show="showCreateAlbum === true" class="md-primary dialog__btn md-raised md-theme-light"
              @click.native="createAlbum()">Save
            </md-button>
          </div>
        </div>
      </div>

      <div
        class="md-layout-item md-raised md-xlarge-size-90 md-large-size-90 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
        <AppSelectNumberEnergyInput v-if="isEditFormModal" required :energyData.sync="song.energy"
          class="recurring-form__field date-pick-field custom-input__large md-layout-item md-size-100"
          errorText="Energy is required" />
      </div>

      <div style="margin: 0 auto" v-if="this.song.streamingIds && this.song.streamingIds.length !==0">
        <md-table>
          <md-table-row>
            <md-table-head>streaming Id</md-table-head>
            <md-table-head>storefront Id</md-table-head>
            <md-table-head>content rating</md-table-head>
            <md-table-head>preview</md-table-head>
          </md-table-row>

          <md-table-row v-for="(row, index) in song.streamingIds" :key="index" :value="row">
            <md-table-cell>{{ row.streaming_id }}</md-table-cell>
            <md-table-cell> {{ row.store_front }}</md-table-cell>
            <md-table-cell> {{ row.content_rating }}</md-table-cell>
            <md-table-cell>
              <a @click.prevent="row.music_service_id == 1 ? play(row): playSpotifyTrack(row)">
                <md-icon :style="{ color: row.music_service_id == 1 ? 'red' : 'green' }" class=" pause md-size-2x"
                  v-if="(row.music_service_id == 2 && isSpotifyPLaying && currentPlayingSpotifySongStreamingId ==row.streaming_id) || (row.music_service_id == 1 && isPLaying && currentPlayingSongStreamingId ==row.streaming_id)">
                  pause_circle_filled
                </md-icon>
                <md-icon :style="{ color: row.music_service_id == 1 ? 'red' : 'green' }" class="md-size-2x play"
                  v-else>play_circle_filled
                </md-icon>
              </a>
            </md-table-cell>

          </md-table-row>
        </md-table>
        <div class="media-player">
          <span>Apple Music:</span><apple-music-progress theme="dark"></apple-music-progress>
        </div>
      </div>

      <div
        class="md-layout-item md-raised md-xlarge-size-95 md-large-size-95 md-medium-size-100 md-small-size-100 md-xsmall-size-100 upload-cont">
        <md-dialog-actions class="form-buttons">
          <md-button class="dialog__btn dialog__btn--danger" id="closeBtn" @click="closeDialog()">Cancel</md-button>

          <md-button class="md-primary dialog__btn md-raised md-theme-light" id="saveSongBtn" v-if="!buttonClicked"
            :disabled="$v.song.$invalid || ((song.energy === 0 && song.energy) && song.processing_status_id !== 5) || showCreateAlbum || showCreateArtist"
            @click.native="beforeSaving()">{{ isEditFormModal ? 'SAVE' : 'SUBMIT' }}
          </md-button>

          <md-select class="save-dialogue" v-if="showChangeStatus && buttonClicked" v-model="newProcessingStatus">
            <md-option style="text-align: center" disabled value="" selected="selected">Change status to</md-option>
            <md-option v-if="song.processing_status_id === 1" value="1">Save without changing</md-option>
            <md-option v-if="song.processing_status_id === 2" value="2">Save without changing</md-option>
            <md-option  v-if="song.processing_status_id != 4" value="3">{{ song.processing_status_id === 3 ? 'Save without changing' : 'Waiting Review'}}</md-option>
            <md-option  v-if="song.processing_status_id != 4" value="4">Reviewed</md-option>
            <md-option value="6">Spotify Matched</md-option>
          </md-select>
        </md-dialog-actions>
      </div>
    </form>
  </md-dialog>
</template>

<script>

import helpers from "../../store/helpers";
import {required, requiredIf} from 'vuelidate/lib/validators';
import {mapState, mapMutations, mapGetters} from "vuex";
import ArtistService from "../../services/artist.service";
import PublisherService from "../../services/publisher.service";
import AlbumService from "../../services/album.service";
import userService from "../../services/user.service";

export default {
  name: 'SongFormModal',
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    songProp: {
      type: Object,
      default: function () {
        return {
          title: '',
          file: '',
          label_end_date: '',
          genres: [],
          streamingIds: [],
          processing: '',
          artist_id: '',
          publisher_id: '',
          album_id: '',
          processing_status_id: '',
        }
      }
    }
  },

  data: function () {
    return {
      song: {
        title: '',
        file: '',
        label_end_date: '',
        artist_id: '',
        publisher_id: '',
        album_id: '',
        processing_status_id: '',
        streamingIds: [],
        processing: '',
        energy: null,
        active: 1
      },
      buttonClicked: false,
      genres: [],
      publishers: [],
      albums: [],
      artists: [],
      searchTerm: '',
      loading: false,
      showChangeStatus: false,
      newProcessingStatus: '',
      showCreateArtist: false,
      newArtistName: '',
      showCreatePublisher: false,
      newPublisherName: '',
      showCreateAlbum: false,
      newAlbumName: '',
      isLicensed: false,
      newMoodName: '',
      previewUrl: '',
      showPhotoError: true,
      isEditFormModal: false,
      isPLaying: false,
      isPopular:false,
      currentPlayingSongStreamingId:null,
      isRRPicks:false,
      photoError:'You must upload a music file (mp3, wav , m4a) to add a new song !',
      searchTermSpotify: '', // for Spotify search
      loadingSpotify: false,
      searchResultsSpotify: null,
      spotifyToken: null,
      spotifyStreamingIds: [],
      spotifyUserToken: null,
      isSpotifyPLaying: false,
      currentPlayingSpotifySongStreamingId:null,

    }
  },

  validations() {
    let song = {
      title: {required},
      artist_id: {required},
      publisher_id: {required: requiredIf(() => this.isLicensed),}
    };
    if (!this.song.hasOwnProperty('id')) {
      song.file = {required};
    }
    if (this.isEditFormModal && this.song.processing_status_id !== 5) {
      song.energy = {required};
    }
    return {
      song,
      newArtistName: {required},
      newPublisherName: {required}
    }
  },

  watch: {
    newProcessingStatus() {
      this.song.processing_status_id = this.newProcessingStatus
      this.saveSong()
    },
    async searchTerm() {
      if (!this.searchTerm) return;
      this.loading = true;
      const music = await MusicKit.getInstance();

      try {
        const queryParameters = {term: this.searchTerm, types: ['songs'], limit: 25};
        const searchResult = await music.api.music(`/v1/catalog/${music.storefrontCountryCode}/search`, queryParameters);

        this.setSearchResults(searchResult);
        this.loading = false;
      } catch (e) {
        console.log('error', e)
        this.loading = false;
      }

    },
    async searchTermSpotify() {
      if (!this.searchTermSpotify) return;
      this.loadingSpotify = true;
      try {
        const query = `track:${this.searchTermSpotify}`;
        const params = new URLSearchParams({
          q: query,
          type: 'track',
          market: 'US',
        }).toString();

        const response = await fetch(`https://api.spotify.com/v1/search?${params}`, {
          headers: {
            Authorization: `Bearer ${this.spotifyToken}`,
          },
        });
        const searchResult = await response.json();

        this.searchResultsSpotify = searchResult;
        this.loadingSpotify = false;
      } catch (e) {
        console.log('error', e);
        this.loadingSpotify = false;
      }
    },
  },

  computed: {
    ...mapState('music', {
      searchText: (state) => state.search,
      searchResults: (state) => state.searchResults,
      isAuthorized: (state) => state.auth.isAuthorized,
    }),
    ...mapGetters(
				{
					currentUser: 'auth/getCurrentUser',
				},
			)
  },

  methods: {
    async play(row) {
      const music = await MusicKit.getInstance()
      if (this.currentPlayingSongStreamingId == row.streaming_id && this.isPLaying == false) {
        this.isPLaying = true;
        music.play()
      } else if (this.currentPlayingSongStreamingId != row.streaming_id || this.isPLaying == false) {
        music.setQueue({song: row.streaming_id}).then(() => {
          music.play().then(() => {
            this.isPLaying = true;
            this.currentPlayingSongStreamingId = music.nowPlayingItem.id;
          }).catch((e) => {
            if (e.errorCode === "CONTENT_RESTRICTED") {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Please log into your Apple Music account and allow Explicit music.",
                duration: 8000,
                mode: 'fail'
              });
            } else {
              this.$store.dispatch('showSnackbarMessage', {
                message: "Something went wrong. Try again.",
                duration: 4000,
                mode: 'fail'
              });
            }
          })
        })
      } else {
        music.pause().then(() => {
          this.isPLaying = false;
        });
      }
    },
    async playSpotifyTrack(row) {
      const player = this.$store.getters.spotifyPlayer; // Access player from Vuex
      if (!player) {
        try {
          await this.$store.dispatch('refreshAccessToken'); // Refresh token before initializing the player
          this.spotifyUserToken = this.$store.getters.spotifyToken;
          if(this.spotifyUserToken) {

            const playerInitPromise = this.$store.dispatch('initSpotifyPlayer', this.spotifyUserToken);
                const timeoutPromise = new Promise((_, reject) => 
                    setTimeout(() => reject(new Error("Spotify Player Initialization Timed Out. Please ensure the app is spotify authorized and that your Spotify account is premium.")), 5000) // 5 seconds timeout
                );

                // Wait for either the player to initialize or the timeout
                await Promise.race([playerInitPromise, timeoutPromise]);
                console.log("Spotify player initialized");  
          }
          else {
            this.$store.dispatch('showSnackbarMessage', {
                message: "Please Authorize your Spotify Music account from account info page!",
                duration: 8000,
                mode: 'fail'
              });
          }
        } catch (error) {
            this.$store.dispatch('showSnackbarMessage', {
                message: "Spotify Player Initialization Timed Out. Please ensure the app is spotify authorized and that your Spotify account is premium.", // Show the error message to the user
                duration: 7000,
                mode: 'fail'
            });
            return; // Exit the method if the player fails to initialize
        }
    }
      // const music = await MusicKit.getInstance()
      if (this.currentPlayingSpotifySongStreamingId == row.streaming_id && this.isSpotifyPLaying == false) {
        this.isSpotifyPLaying = true;
        try {
          console.log('togglePlay');
          await this.$store.dispatch('togglePlay');
        } catch (error) {
          console.log('Error toggling play:', error);
          this.$store.dispatch('showSnackbarMessage', {
            message: "Error toggling playback. Please try again.",
            duration: 4000,
            mode: 'fail'
          });
          this.isSpotifyPLaying = false; // Reset the playing state
        }
      } else if (this.currentPlayingSpotifySongStreamingId != row.streaming_id || this.isSpotifyPLaying == false) {
            try {
              await this.$store.dispatch('playSong',row.streaming_id);
              this.isSpotifyPLaying = true;
              this.currentPlayingSpotifySongStreamingId = row.streaming_id;
          } catch(e)  {
            console.log(e);
            this.$store.dispatch('showSnackbarMessage', {
                message: error.message, // Display the error message from the catch
                duration: 4000,
                mode: 'fail'
            });          }
        
      } else {
        try {
          await this.$store.dispatch('togglePlay');
          this.isSpotifyPLaying = false;
        } catch (error) {
          console.log('Error toggling play:', error);
          this.$store.dispatch('showSnackbarMessage', {
            message: "Error toggling playback. Please try again.",
            duration: 4000,
            mode: 'fail'
          });
        }

      }
    },
    async appleSong(song) {

      let cleanSong = null;
      if (song.attributes.contentRating === 'explicit') {
        try {
          const music = await MusicKit.getInstance()
          const queryParameters = {'filter[equivalents]': song.id, restrict: 'explicit'};
          const response = await music.api.music(`/v1/catalog/${music.storefrontCountryCode}/songs`, queryParameters);
          cleanSong = response.data.data;
        } catch (e) {
          console.log('error', e)
        }
      }

      this.searchTerm = ''
      this.song.title = song.attributes.name;
      let tempSpotify= this.song.streamingIds.filter(id => id.music_service_id == 2);
      console.log("tempSpotify",tempSpotify)
      this.song.streamingIds = [{
        "streaming_id": song.id,
        "store_front": 'us',
        "content_rating": song.attributes.contentRating ? song.attributes.contentRating : 'clean',
        "music_service_id" : 1
      }]
      if (cleanSong && cleanSong.length !== 0) {
        cleanSong = cleanSong[0];
        this.song.streamingIds.push(
            {
              "streaming_id": cleanSong.id,
              "store_front": 'us',
              "content_rating": cleanSong.attributes.contentRating ? cleanSong.attributes.contentRating : 'clean',
              "music_service_id_id" : 1
            }
        );
      }
      this.song.streamingIds.push(...tempSpotify);
      console.log( this.song.streamingIds)
      this.genres = song.attributes.genreNames;
      let temp = this.artists.find(object => object.name === song.attributes.artistName)
      if (temp) {
        this.song.artist_id = temp.id;
      } else {
        this.showCreateArtist = true
        this.newArtistName = song.attributes.artistName
      }
      temp = this.albums.find(object => object.name === song.attributes.albumName)
      if (temp) {
        this.song.album_id = temp.id;
      } else {
        this.showCreateAlbum = true
        this.newAlbumName = song.attributes.albumName
      }

    },
    async spotifySong(song) {
    let cleanSong = null;

    // Check if the song is explicit
    if (song.explicit) {
      try {
          const query = `track:${encodeURIComponent(song.name)}%20artist:${encodeURIComponent(song.artists[0].name)}%20album:${encodeURIComponent(song.album.name)}`;
          const response = await fetch(`https://api.spotify.com/v1/search?q=${query}&type=track`, {
              headers: {
                  'Authorization': `Bearer ${this.spotifyToken}` // Replace with your actual access token
              }
          });
          const data = await response.json();

          if (data.tracks && data.tracks.items.length > 0) {
              cleanSong = data.tracks.items.find(track => !track.explicit); // Find a track that is not explicit
          }
        } catch (e) {
            console.log('error fetching clean song:', e);
        }
    }
  
    this.searchTermSpotify = '';
    this.song.streamingIds = this.song.streamingIds.filter(id => id.music_service_id == 1);
    this.song.streamingIds.push({
        "streaming_id": song.uri,
        "store_front": 'us',
        "content_rating": song.explicit ? 'explicit' : 'clean',
        "music_service_id" : 2
    });

    if (cleanSong && cleanSong.length !== 0) {
        this.song.streamingIds.push({
            "streaming_id": cleanSong.uri,
            "store_front": 'us',
            "content_rating": cleanSong.explicit ? 'explicit' : 'clean',
            "music_service_id" : 2
        });
    }

},

    getUrl(artwork, size) {
      const fakeArtwork =
          'https://is1-ssl.mzstatic.com/image/thumb/Features127/v4/75/f9/6f/75f96fa5-99ca-0854-3aae-8f76f5cb7fb5/source/200x200bb.jpeg';
      return helpers.getSafe(
          () => MusicKit.formatArtworkURL(artwork, size, size),
          fakeArtwork,
      );
    },
    ...mapMutations('music', {
      setSearch: 'setSearch',
      setSearchResults: 'setSearchResults',
      setSearchType: 'setSearchType',
      setSearchTyping: 'setSearchTyping',
    }),
    createArtist() {
      let _this = this;
      this.$axios({
        method: 'post',
        url: '/artists',
        data: {
          name: _this.newArtistName
        }
      })
          .then(function (response) {

            _this.$store.dispatch('showSnackbarMessage', {
              message: 'You have successfully added an artist.',
              duration: 4000,
              mode: 'success'
            });

            _this.allArtists().then(() => {

              _this.song.artist_id = response.data.id;
            });

            _this.showCreateArtist = false;
          })
          .catch(function (error) {

            console.log('error', error)

            _this.$store.dispatch('showSnackbarMessage', {
              message: "Something went wrong. Try again.",
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    createPublisher() {
      let _this = this;

      this.$axios({
        method: 'post',
        url: '/publishers',
        data: {
          name: _this.newPublisherName
        }
      })
          .then(function (response) {

            _this.$store.dispatch('showSnackbarMessage', {
              message: 'You have successfully added an artist.',
              duration: 4000,
              mode: 'success'
            });

            _this.allPublishers().then(() => {

              _this.song.publisher_id = response.data.id;
            });

            _this.showCreatePublisher = false;
          })
          .catch(function (error) {

            console.log('error', error)

            _this.$store.dispatch('showSnackbarMessage', {
              message: "Something went wrong. Try again.",
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    createAlbum() {
      let _this = this;

      this.$axios({
        method: 'post',
        url: '/albums',
        data: {
          name: _this.newAlbumName
        }
      })
          .then(function (response) {

            _this.$store.dispatch('showSnackbarMessage', {
              message: 'You have successfully added an album.',
              duration: 4000,
              mode: 'success'
            });

            _this.allAlbums().then(() => {

              _this.song.album_id = response.data.id;
            });

            _this.showCreateAlbum = false;
          })
          .catch(function (error) {

            console.log('error', error)

            _this.$store.dispatch('showSnackbarMessage', {
              message: "Something went wrong. Try again.",
              duration: 4000,
              mode: 'fail'
            });
          });
    },

    disabledDates(date) {
      return new Date(Date.now()) >= date;
    },

    allPublishers() {
      return PublisherService.getAllPublishers()
          .then((res) => {

            this.publishers = res;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },

    allArtists() {
      return ArtistService.getAllArtist()
          .then((res) => {

            this.artists = res;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },

    allAlbums() {
      return AlbumService.getAllAlbums()
          .then((res) => {

            this.albums = res;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },

    showPreview(event) {
      const input = event.target;

      if (input.files && input.files[0]) {

        let splitFile = input.files[0].name.split('.');
        let splitFileLength = splitFile.length;
        let fileExtension = input.files[0].name.split('.')[splitFileLength - 1];
        const maxSize = 10 * 1024 * 1024;
        if (input.files[0].size > maxSize) {
          this.showPhotoError = true;
          this.photoError = 'File size must be less than 10 MB!';
          this.$store.dispatch('showSnackbarMessage', {
              message: "File size must be less than 10 MB",
              duration: 4000,
              mode: 'fail'
            });

          return;
        }
        else{
          this.showPhotoError = false;
          this.photoError = '';
        }
        if (fileExtension === 'mp3' || fileExtension === 'wav' || fileExtension === 'm4a') {

          this.song.file = input.files[0];

          this.previewUrl = input.files[0].name;

          const reader = new FileReader();

          reader.onload = e => {
            input.value = '';
          };

          reader.readAsDataURL(input.files[0]);

          this.showPhotoError = false;
          this.photoError = '';
        } else {
          this.photoError = 'You must upload a music file (mp3, wav , m4a) to add a new song !';
          this.showPhotoError = true;
        }
      }
    },

    async closeDialog() {
      const music = await MusicKit.getInstance()
      if (!music.queueIsEmpty) {
        music.stop()
      }
      this.$store.dispatch('pause');
      this.$emit('update:showModal', false);
    },

    beforeSaving() {
      if (this.showPhotoError && !this.isEditFormModal ) {
         this.$store.dispatch('showSnackbarMessage', {
              message: "File size must be less than 10 MB",
              duration: 4000,
              mode: 'fail'
            });
        return;
      }
      this.buttonClicked = true;
      if (this.isEditFormModal && !this.song.processing && (this.song.processing_status_id <= 3  || this.song.processing_status_id == 5 || this.song.status_flag == 'NO_MATCHES')) {
        this.showChangeStatus = true;
      } else {
        this.saveSong();
      }
    },

    saveSong() {
      let _this = this;
      let songObj = this.song;

      let url = '/songs';

      if (songObj.hasOwnProperty('id')) {

        url = '/songs/' + songObj.id;
      }

      let bodyFormData = new FormData();
      bodyFormData.set('title', songObj.title);
      bodyFormData.set('file', songObj.file);

      if (songObj.label_end_date) {
        bodyFormData.set('license_end_date', songObj.label_end_date);
      }

      bodyFormData.set('artist_id', songObj.artist_id);
      bodyFormData.set('genres', JSON.stringify(_this.genres));
      bodyFormData.set('streamingIds', JSON.stringify(songObj.streamingIds));

      if (songObj.publisher_id) {
        bodyFormData.set('publisher_id', songObj.publisher_id);
      }
      if (songObj.album_id) {
        bodyFormData.set('album_id', songObj.album_id);
      }
      bodyFormData.set('processing_status_id', songObj.processing_status_id);
      bodyFormData.set('energy', songObj.energy);

      let activeStringFormat = songObj.active ? '1' : '0';
      bodyFormData.set('active', activeStringFormat);
      bodyFormData.set('isPopular', this.isPopular? '1' : '0');
      bodyFormData.set('isRRPicks', this.isRRPicks? '1' : '0');
      this.$axios({
        method: 'post',
        url: url,
        data: bodyFormData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
          .then(function (response) {

            _this.$emit('search');

            _this.$store.dispatch('showSnackbarMessage', {
              message: 'Saved successfully',
              duration: 4000,
              mode: 'success'
            });

          })
          .catch(function (error) {

            console.log('error', error)

            _this.$store.dispatch('showSnackbarMessage', {
              message: "Something went wrong. Try again.",
              duration: 4000,
              mode: 'fail'
            });
          });

      this.closeDialog();
    },

    async fetchData() {

      await this.allArtists();
      await this.allPublishers();
      await this.allAlbums();
    },
    async getSpotifyToken() {
      await this.$axios.get('/spotify/getAccessToken')
          .then((res) => {
            this.spotifyToken =res.data;
          })
          .catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail'
            });
          });
    },
    getCurrentUser() {	
				return userService.getOne(this.currentUser.id)
					.then((data) => {	
						this.spotifyUserToken = data.services.find(sr => sr.service_id == 2)?.token;
					})
					.catch((err) => {	
						this.$store.dispatch('showSnackbarMessage',
							{
								message: `${err.message}. Please, try again.`,
								mode: 'fail'
							});
					});
			}
  },

  async created() {

    let _this = this;

    _this.fetchData().then(() => {
      if (_this.songProp) {

        _this.isEditFormModal = true;
        _this.song = _this.songProp;
        if (Array.isArray(_this.songProp.genres)) {
        _this.genres = _this.songProp.genres.map(genre => genre.name);
      } else {
        _this.genres = []; // Fallback to an empty array if genres is not defined
      }
        // _this.genres = _this.songProp.genres.map(genre=>genre.name);
        _this.streamingIds = _this.songProp.streamingIds;
        if (_this.song.publisher_id) _this.isLicensed = true;
        if (_this.song.is_popular) _this.isPopular = true;
        if (_this.song.is_reflex_radio_pickes) _this.isRRPicks = true;

      }
    });
    _this.getSpotifyToken();
    await _this.getCurrentUser();

  }
}
</script>

<style lang="scss" scoped>
@include dialogSettings();
.dialog__hint {
  margin-bottom: 16px;
}

.scrollbar-div {
  width: 91%;
  background-color: #363d47;
  position: absolute;
  z-index: 2;
  height: 300px;
  overflow-y: scroll
}
.scrollbar-apple {
  top: 115px;
}
.scrollbar-spotify {
  top: 160px;
}
.Licensed-border {
  width: 100%;
  margin-bottom: 20px;
  border: 2px solid #75758d;
  border-radius: 20px;
  display: none;
}

.song {
  display: flex;
  padding: 8px;
  border-radius: 8px;
  width: 95%;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.song-image {
  height: 40px;
  border-radius: 4px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  position: relative;
  background-color: white;
}

.song-details {
  flex-shrink: 0;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;

  a,
  p {
    padding: 0;
    margin: 0;
    color: white;
    text-decoration: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  a:hover {
    text-decoration: underline;
  }

  .explicit-content {
    position: relative;
  }

  .explicit-content::after {
    position: absolute;
    content: 'E';
    top: 3px;
    font-size: 12px;
    font-weight: bold;
    padding: 0.5px 4px;
    background-color: hsla(0, 0%, 70.6%, 0.6);
    margin-left: 6px;
    border-radius: 2px;
  }
}

.active-div {
  padding-left: 30px;
  padding-bottom: 20px
}

.media-player {
  padding-top: 25px;
  width: 250px;
  margin: 0 auto;
}

.md-dialog {

  .choose-file-btn {
    width: 30%;

    @include respond(medium) {
      width: 100%;
    }
  }

  .upload-cont {
    text-align: center;
  }

  .file-upload-label {
    font-size: 2rem;
  }
}

.form-buttons {
  margin-top: 20px;
  justify-content: space-around;
}

.input-file {
  display: none;
}

.create-form {

  .md-layout-item {
    margin: 0 auto;
  }
}

.md-dialog-title {
  text-align: center;
  font-size: 2.3rem;
}

.upload-dialog__preview {
  /*max-width: 100px;*/
  margin: 20px auto;
}

.photo-error {
  color: #FF0900;
  font-size: 1.7rem;
  margin: 7px auto 0;
}

.md-field {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.md-helper-text {
  color: white !important;
}

.save-dialogue {
  max-width: 150px;
  height: 34px;

  ::v-deep input {
    border: 0;
    background: #a156a0;
    color: white;
    text-align: center;
    font-size: 14px;
  }
}
</style>
