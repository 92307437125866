import UserService from "../../services/user.service";

export default async function appleMusicCheck({to, next, store, nextMiddleware}) {

    const user = await UserService.getOne(store.getters['auth/getCurrentUser'].id)

    const pathAppleMusic = '/apple-music';
    const music = await MusicKit.getInstance();
    music.musicUserToken = user.services.length !== 0 ? user.services[0].token : '';
    if (!music.isAuthorized) {
        return next({
            path: pathAppleMusic,
        });
    }


    return nextMiddleware();
}
