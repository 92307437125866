// src/plugins/spotify.js

export default {
    install(Vue) {
        Vue.prototype.$spotify = {
            player: null,
            token: null,
            deviceId: null,
            
            initPlayer(token, store) {
                if (this.player) {
                    console.log('Player is already initialized.');
                    return Promise.resolve(this.player); // Return existing player instance
                }
                console.log("Initializing player");
                this.token = token;
                return new Promise((resolve, reject) => {
                    window.onSpotifyWebPlaybackSDKReady = () => {
                        const player = new window.Spotify.Player({
                            name: 'ReflexRadio Player',
                            getOAuthToken: (cb) => { cb(token); },
                            volume: 0.5
                        });

                        // Error handling
                        player.addListener('initialization_error', ({ message }) => { console.error(message); });
                        player.addListener('authentication_error', ({ message }) => { console.error(message); });
                        player.addListener('playback_error', ({ message }) => { console.error(message); });

                        // Ready
                        player.addListener('ready', ({ device_id }) => {
                            this.deviceId = device_id; // Save device ID
                            this.player = player; // Assign the player instance
                            store.dispatch('updateDeviceId', device_id); // Store player instance in Vuex

                            resolve(player); // Resolve the promise
                        });

                        // Not Ready
                        player.addListener('not_ready', ({ device_id }) => {
                            console.log('Device ID has gone offline', device_id);
                            reject(); // Reject the promise if not ready
                        });
                        console.log("Initialized player");

                        // Connect to the player!
                        player.connect();
                        console.log("player.connect()")
                        this.player = player;
                        console.log('Player instance:', this.player);
                    };
                });
            }
        };
    }
};
