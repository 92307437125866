import $axios from '../plugins/axios'

class SongService {
  getSongs(limit, offset, word = null, search_status = null, energy = null, genre = null) {
    const params = new URLSearchParams();
    params.append('limit', limit);
    params.append('offset', offset);
    if (word != null && word !== '') {
      params.append('word', word);
    }
    if (search_status != null && search_status !== '') {
      params.append('search_status', search_status);
    }
    if (energy != null && energy !== '') {
      params.append('energy', energy);
    }
    if (genre != null && genre !== '') {
      params.append('genre', genre);
    }
    return $axios
        .get(`/songs`, {params})
        .then(res => {
          return res.data
        })
        .catch(error => {
          console.log(error)
          throw error
        })
  }

  exportSongs(limit, offset, totalRecords) {
    return $axios
      .get('/songs/export', {
        params: {
          limit,
          offset,
          totalRecords
        }
      })
      .then(res => res.data)
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  deleteSong (id) {
    return $axios
      .delete(`/songs/${id}`)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  addSong (song) {
    return $axios
      .post('/songs', song)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  editSong (data) {
    return $axios
      .put(`/songs/${data.id}`, data)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  updateSongStatus (data, id) {
    return $axios
      .post(`/songs/${id}`, data)
      .then(res => {
        return res.data
      })
      .catch(error => {
        throw error
      })
  }

  getSong (id) {
    return $axios
      .get(`/songs/${id}`)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  changeSongData (data, id) {
    return $axios
      .put(`/interval-songs/${id}`, data)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  // dislikeSong(data) {
  //
  //     return $axios.post('/user-song-dislikes', data)
  //         .then(res => {
  //
  //             return res.data;
  //         })
  //         .catch((error) => {
  //
  //             console.log(error)
  //             throw error;
  //         })
  // }

  getLikesForSong (id) {
    return $axios
      .get('/user-song-likes', id)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }

  getDislikesForSong (id) {
    return $axios
      .get('/user-song-dislikes', id)
      .then(res => {
        return res.data
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  }
}

export default new SongService()
